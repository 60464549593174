import React, {useState, useEffect, useContext} from "react";
import "moment-timezone";
import AppContext from "../../AppContext";
import axios2 from "../../axios2";
import { Card, Group, Select, Table } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";

const OrdersReport = () => {
    
    const context = useContext(AppContext).context;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [value, setValue] = useState([null, null]);
    const [selectedRange, setSelectedRange] = useState(null); // For Select default value

    // Predefined ranges
    const predefinedRanges = [
        { value: 'last7Days', label: 'Last 7 Days' },
        { value: 'last30Days', label: 'Last 30 Days' },
        { value: 'thisMonth', label: 'This Month' },
    ];

    // Generate Last 12 months dynamically
    const generateLast12Months = () => {
        const now = new Date();
        const options = [];
        for (let i = 0; i < 12; i++) {
        const date = new Date();
        date.setMonth(now.getMonth() - i);
        const label = date.toLocaleString('default', { month: 'long', year: 'numeric' });
        const start = new Date(date.getFullYear(), date.getMonth(), 1);
        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        options.push({
            value: `${start.toISOString()}-${end.toISOString()}`,
            label,
            range: [start, end],
        });
        }
        return options;
    };

    const last12MonthsRanges = generateLast12Months();

    // Combine predefined ranges and last 12 months
    const allRanges = [
        ...predefinedRanges,
        ...last12MonthsRanges.map((item) => ({
        value: JSON.stringify([item.range[0], item.range[1]]),
        label: item.label,
        })),
    ];

    const handleRangeChange = (selectedRange) => {
        setSelectedRange(selectedRange);
        if (predefinedRanges.some((r) => r.value === selectedRange)) {
        // Handle predefined ranges
        const now = new Date();
        let start, end;

        switch (selectedRange) {
            case 'last7Days':
            end = new Date();
            start = new Date();
            start.setDate(end.getDate() - 7);
            break;
            case 'last30Days':
            end = new Date();
            start = new Date();
            start.setDate(end.getDate() - 30);
            break;
            case 'thisMonth':
            start = new Date(now.getFullYear(), now.getMonth(), 1);
            end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
            break;
            default:
            return;
        }

        setValue([start, end]);
        } else {
        // Handle last 12 months ranges
        const [startDate, endDate] = JSON.parse(selectedRange);
        setValue([new Date(startDate), new Date(endDate)]);
        }
    };
    useEffect(() => {
        const now = new Date();
        const firstDayOfPrevMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const lastDayOfPrevMonth = new Date(now.getFullYear(), now.getMonth(), 0);

        // Set default for DateRangePicker
        setValue([firstDayOfPrevMonth, lastDayOfPrevMonth]);

        // Set default for Select
        const defaultRangeValue = JSON.stringify([firstDayOfPrevMonth, lastDayOfPrevMonth]);
        setSelectedRange(defaultRangeValue);
    }, []);

    useEffect(()=>{
        update();
    }, [value, context.doctor_id]);

    const update = function () {
        if(!value?.length || !value[0] || !value[1]) return;
        setLoading(true);
        axios2.get('/report/dr/orders', {params: {
            start_date: getDate(value[0]),
            end_date: getDate(value[1]),
            doctor_id: context.doctor_id
        }}).then((resp) => {
            const p = resp.data.data;
            setData(p);
        }).finally(() => setLoading(false))
    }

    const getDate = (date) => {
        if(!(date instanceof Date)) return null;
        return date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
    }

    return (
        <>
            <Card>
            <Group mt="sm">
                <DatePickerInput
                    style={{ width: "350px" }}
                    type="range"
                    label="Date"
                    placeholder="Pick dates"
                    value={value}
                    onChange={setValue}
                />
                <Select
                data={allRanges}
                value={selectedRange}
                placeholder="Select a date range"
                label="Pre-Defined Ranges"
                onChange={handleRangeChange}
                />
            </Group>
            </Card>
            <Card>
            {loading ? (
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : <Table striped withTableBorder withColumnBorders>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>State</Table.Th>
                  <Table.Th>All Orders</Table.Th>
                  <Table.Th>Completed</Table.Th>
                  <Table.Th>Inprogress</Table.Th>
                  <Table.Th>Renewal (comes from old app)</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {data?.orders?.length > 0 ? (
                  data.orders?.map((row) => (
                    <Table.Tr key={row.state_id}>
                      <Table.Td>{row.state_name}</Table.Td>
                      <Table.Td>{row.all_orders}</Table.Td>
                      <Table.Td>{row.done_orders}</Table.Td>
                      <Table.Td>{row.inprogress_orders}</Table.Td>
                      <Table.Td>{row.renewal_orders}</Table.Td>
                    </Table.Tr>
                  ))
                ) : (
                  <Table.Tr>
                    <Table.Td colSpan="5">
                      <center>-- No Data --</center>
                    </Table.Td>
                  </Table.Tr>
                )}
              </Table.Tbody>
            </Table>}
            </Card>
        </>
    );
};
export default OrdersReport;
