import React, { useContext, useEffect } from "react";
import "@progress/kendo-theme-material/dist/all.css";
import { Routes, Route, useSearchParams } from "react-router-dom";
import Register from "./pages/patient/Register";
import Resetpassword from "./pages/patient/Resetpassword";
import Registersuccessful from "./pages/patient/Registersuccessful";
import CommonUploadDoc from "./pages/common/UploadDoc";
import CommonSelectPlans from "./pages/common/Plans";
import Videocall from "./pages/patient/Videocall";
import "./assets/website/css/preloader.css";
import Contactus from "./pages/patient/Contactus";
import CommonViewprofile from "./pages/common/ViewProfile";
import CommonChangepassword from "./pages/common/ChangePassword";
import CommonProfileupdate from "./pages/common/EditProfile";
import Layouts from "./pages/patient/Layouts";
import CommonLayouts from "./pages/common/Layouts";
import Verifyrecommendation from "./pages/patient/Verifyrecommendation";
import FirstOrderLayout from "./pages/patient/FirstOrderLayout";

import DoctorLogin from "./pages/doctor/Login";
import CommonLogin from "./pages/common/Login";
import CommonDashboard from "./pages/common/Dashboard";
import DoctorForgotpasword from "./pages/doctor/Forgotpassword";
import DoctorResetpassword from "./pages/doctor/Resetpassword";
import DoctorContactus from "./pages/doctor/Concatus";
import DoctorLayout from "./pages/doctor/Layout";
import DoctorEditprofile from "./pages/doctor/Editprofile";
import DoctorChangepassword from "./pages/doctor/Changepassword";
import DoctorMyprofile from "./pages/doctor/Myprofile";
import DoctorVideocall from "./pages/doctor/Videocall";
import DoctorPatientprofile from "./pages/doctor/Patientprofile";
import DoctorOrderList from "./pages/doctor/OrderList";
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';

import CommonPayment from "./pages/common/Payment";
import CommonOrders from "./pages/common/Orders";
import OrderDetails from "./pages/doctor/OrderDetails";
import IncomeReport from "./pages/doctor/IncomeReport";
import Verify from "./pages/Verify";
import LoginByToken from "./pages/common/LoginByToken";
import Renew from "./pages/common/Renew";
import Questionnaire from "./pages/common/Questionnaire";
import Unsubscribe from "./pages/common/Unsubscribe";
import AppContext from "./AppContext";
import LeadList from "./pages/doctor/LeadList";
import LeadDetails from "./pages/doctor/LeadDetails";
import moment from "moment";
import SteadyCareVerify from "./pages/common/SteadyCareVerify";
import OrdersReport from "./pages/doctor/OrdersReport";

moment.tz.setDefault('America/Los_Angeles');
moment.locale('en');
moment.updateLocale("en", { week: {
  dow: 1, // First day of week is Monday
}});

function App() {
  const { context } = useContext(AppContext);
  useEffect(() => {
    if (context.user.role !== "ROLE_DOCTOR") {
      const script = document.createElement("script");
      script.id = "tawk_to";
      script.async = true;
      script.innerHTML = `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/662a61b7a0c6737bd13052ac/1hsaorerj';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();`;
      document.head.appendChild(script);
    } else {
      const existingScript = document.getElementById("tawk_to");
      if (existingScript) {
        existingScript.remove();
        if (window.Tawk_API) {
          window.Tawk_API.hideWidget();
        }
      }
    }
  }, [context.user]);
  const [searchParams] = useSearchParams();
  if (searchParams.get("testing")) {
    localStorage.setItem("is_testing", 1);
  }

  return (
    <>
      <Routes key={context.user}>
        <Route
          path="/"
          element={
            <CommonLayouts>
              <CommonDashboard />
            </CommonLayouts>
          }
        />
        <Route path="/login" element={<CommonLogin />} />
        <Route path="/login-by-token" element={<LoginByToken />} />
        <Route
          path="/dashboard"
          element={
            <CommonLayouts>
              <CommonDashboard />
            </CommonLayouts>
          }
        />
        <Route
          path="/videocall"
          element={
            <Layouts>
              <Videocall />
            </Layouts>
          }
        />
        <Route
          path="/viewProfile"
          element={
            <CommonLayouts>
              <CommonViewprofile />
            </CommonLayouts>
          }
        />
        <Route
          path="/profileUpdate"
          element={
            <CommonLayouts>
              <CommonProfileupdate />
            </CommonLayouts>
          }
        />
        <Route
          path="/orders/omc"
          element={
            <CommonLayouts>
              <CommonOrders site="omc" />
            </CommonLayouts>
          }
        />
        <Route
          path="/orders/esa"
          element={
            <CommonLayouts>
              <CommonOrders site="esa" />
            </CommonLayouts>
          }
        />
         <Route
          path="/leads"
          element={
            <CommonLayouts>
              <LeadList />
            </CommonLayouts>
          }
        />
        <Route
          path="/appointment"
          element={
            <SteadyCareVerify />
          }
        />
        <Route
          path="/orders"
          element={
            <CommonLayouts>
              <CommonOrders />
            </CommonLayouts>
          }
        />
        <Route
          path="/report/orders"
          element={
            <CommonLayouts>
              <OrdersReport />
            </CommonLayouts>
          }
        />
        <Route
          path="/report"
          element={
            <CommonLayouts>
              <IncomeReport />
            </CommonLayouts>
          }
        />
        <Route
          path="/orders/:id"
          element={
            <CommonLayouts>
              <OrderDetails />
            </CommonLayouts>
          }
        />
        <Route
          path="/leads/:id"
          element={
            <CommonLayouts>
              <LeadDetails />
            </CommonLayouts>
          }
        />
        <Route
          path="/orders/:id/renew"
          element={
            <CommonLayouts>
              <Renew />
            </CommonLayouts>
          }
        />
        <Route
          path="/changePassword"
          element={
            <CommonLayouts>
              <CommonChangepassword />
            </CommonLayouts>
          }
        />
        <Route
          path="/contactUs"
          element={
            <CommonLayouts>
              <Contactus />
            </CommonLayouts>
          }
        />
        <Route
          path="/videocall/:id"
          element={
            <CommonLayouts>
              <Videocall />
            </CommonLayouts>
          }
        />
        <Route path="/register" element={<Register />} />
        <Route path="/registerSuccessfully" element={<Registersuccessful />} />
        <Route
          path="/order/medical-info"
          element={
            <FirstOrderLayout>
              <CommonUploadDoc />
            </FirstOrderLayout>
          }
        />
        <Route
          path="/unsubscribe"
          element={
            <FirstOrderLayout>
              <Unsubscribe />
            </FirstOrderLayout>
          }
        />
        <Route
          path="/questionnaire/:id"
          element={
            <FirstOrderLayout>
              <Questionnaire />
            </FirstOrderLayout>
          }
        />
        <Route
          path="/order/plan"
          element={
            <FirstOrderLayout>
              <CommonSelectPlans />
            </FirstOrderLayout>
          }
        />
        <Route
          path="/order/payment"
          element={
            <FirstOrderLayout>
              <CommonPayment />
            </FirstOrderLayout>
          }
        />
        <Route
          path="/verify"
          element={
            <FirstOrderLayout>
              <Verify />
            </FirstOrderLayout>
          }
        />
        <Route path="/resetPassword" element={<Resetpassword />} />
        <Route
          path="/verifyRecommendation"
          element={<Verifyrecommendation />}
        />

        <Route
          path="doctor/contactus"
          element={
            <DoctorLayout>
              <DoctorContactus />
            </DoctorLayout>
          }
        />
        <Route
          path="doctor/edit-profile/:id"
          element={
            <DoctorLayout>
              <DoctorEditprofile />
            </DoctorLayout>
          }
        />

        <Route
          path="doctor/change-password"
          element={
            <DoctorLayout>
              <DoctorChangepassword />
            </DoctorLayout>
          }
        />
        <Route
          path="doctor/my-profile"
          element={
            <DoctorLayout>
              <DoctorMyprofile />
            </DoctorLayout>
          }
        />
        <Route
          path="doctor/patient-profile/:id"
          element={
            <DoctorLayout>
              <DoctorPatientprofile />
            </DoctorLayout>
          }
        />
        <Route
          path="doctor/"
          element={
            <CommonLayouts>
              <DoctorOrderList />
            </CommonLayouts>
          }
        />
        <Route
          path="doctor/video-call/:id"
          element={
            <DoctorLayout>
              <DoctorVideocall />
            </DoctorLayout>
          }
        />

        <Route path="doctor/login" element={<DoctorLogin />} />
        <Route path="doctor/forgotPassword" element={<DoctorForgotpasword />} />
        <Route path="doctor/resetPassword" element={<DoctorResetpassword />} />
      </Routes>
    </>
  );
}

export default App;
