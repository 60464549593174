import Footer from "./Footer";
import React, { createElement, useContext, useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Flex,
  Card,
  Image,
  Indicator,
  Modal,
  Text,
  Textarea,
} from "@mantine/core";
import AppContext from "../../AppContext";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
  FaPen,
  FaPhone,
  FaPhoneAlt,
  FaTextHeight,
  FaTimes,
  FaTimesCircle,
  FaVideo,
} from "react-icons/fa";
import axios from "../../axios2";
import { Link, useNavigate } from "react-router-dom";
import Helpers from "../../components/doctor/Helper";
import NavItem from "./NavItem";
import { VonageClient, LoggingLevel } from "@vonage/client-sdk";
import { notifications } from "@mantine/notifications";
import { FaDeleteLeft } from "react-icons/fa6";
import "../../vonage.css";
import ChatBox from "../doctor/ChatBox";
const Layouts = ({ children }) => {
  const { context, setContext, keypad, activeKeypad, chat } = useContext(AppContext);
  const [opened, { open, close }] = useDisclosure(false);
  const [rooms, setRooms] = useState([]);
  const navigate = useNavigate();
  const role = context.user?.role;

  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState({});

  const [changeDoctorModalOpened, changeDoctorModal] = useDisclosure();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const [client, setClient] = useState();
  const [session, setSession] = useState();
  const [vonageUsername, setVonageUsername] = useState();
  const [callId, setCallId] = useState();

  const [chatBoxOpen, setChatBoxOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState("");
  const [incomingCall, setIncomingCall] = useState(false);
  const [answered, setAnswered] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [userData, setUserData] = useState([]);
  const [jwtToken, setJwtToken] = useState(null);
  const [callStatus, setCallStatus] = useState();
  const isSideBarOpen = !document.body.classList.contains("collapsed-sidebar");
  document.addEventListener("DOMContentLoaded", () => {});
  useEffect(() => {
    if (
      context.user.available_for_call ||
      context.user.role == "ROLE_PATIENT"
    ) {
      axios.post("call").then((res) => setJwtToken(res.data.token));
    }
  }, []);

  useEffect(() => {
    if(chat.mobile) setChatBoxOpen(true);
  }, [chat]);

  function answer() {
    client
      .answer(callId)
      .then(() => {
        setAnswered(true);
      })
      .catch((error) => {
        console.error("Error answering call: ", error);
      });
  }
  function reject() {
    setIncomingCall(false);
    setCallId(null);
  }
  function incomingHangUp() {
    client.hangUp(callId).then(() => {
      setAnswered(false);
      setIncomingCall(false);
      setCallId(null);
    });
  }
  function getUserData(number) {
    return axios
      .get(`user/search?mobile=${number}`)
      .then((res) => res?.data)
      .catch((error) => console.log("error fetching user info", error));
  }
  function dial(number) {
    setNumber(number);
    setIsCalling(true);
    getUserData(number).then((data) => setUserData(data || []));
    notifications.show({
      message: "Making a call to your destination...",
    });
    activeKeypad();
    client
      .serverCall({ to: number })
      .then((_callId) => {
        setCallId(_callId);
        notifications.show({ message: "Call connected" });
      })
      .catch((error) => {
        console.log("error making a call", error);
        notifications.show({ message: error.message });
      });
  }

  useEffect(() => {
    if (!jwtToken) return;
    const vonageClient = new VonageClient();
    setClient(vonageClient);
    vonageClient
      .createSession(jwtToken)
      .then((sessionId) => console.log("Id of created session", sessionId))
      .catch((error) => console.log("error creation session", error));
  }, [jwtToken]);
  useEffect(() => {
    client?.on("callInvite", (_callId) => {
      notifications.show({ message: "Incoming call..." });
      setCallId(_callId);
      setIncomingCall(true);
    });
    client?.on("callHangup", (_callId) => {
      setCallId(null);
      setIncomingCall(false);
      notifications.show({ message: "Call hung up." });
    });
    client?.on("legStatusUpdate", (callId, legId, status) => {
      setCallStatus(status.g3_1);
      if (status.g3_1 === "ANSWERED") {
        setCallStatus(status.g3_1);
      }
      if (status.g3_1 === "COMPLETED") {
        setCallStatus(status.g3_1);
        setIsCalling(false);
        setIncomingCall(false);
      }
      console.log(status);
    });
  }, [client]);
  function hangUp() {
    notifications.show({ message: "Hanging up call..." });
    client
      .hangup(callId)
      .then(() => {
        activeKeypad();
        setIsCalling(false);
        setNumber("");
        setUserData([]);
        notifications.show({ message: "the call has been hung up" });
        setCallId(null);
      })
      .catch((error) => {
        notifications.show({
          message: "There was an error hanging up your call,try again",
        });
      });
  }

  useEffect(() => {
    if (context.user.role == "ROLE_DOCTOR") {
      getRooms();
      if (context.user.change_doctor) {
        getDoctors();
      }
    }
  }, [context]);

  const getRooms = () => {
    axios.get("video/list").then((resp) => {
      setRooms(resp.data.data);
      if (context.user.role == "ROLE_DOCTOR")
        setTimeout(() => getRooms(), 5000);
    });
  };

  const getDoctors = () => {
    axios.get("user/doctors").then((resp) => {
      setDoctors(resp.data.data);
      const doctor_id = localStorage.getItem("selected_doctor");
      if (doctor_id) {
        setSelectedDoctor(resp.data.data.find((d) => d.id == doctor_id));
      }
    });
  };

  const accept = (id) => {
    setLoading(true);
    axios.post("video/accept/" + id).then((resp) => {
      navigate("/orders/" + resp.data.data.order_id);
      setLoading(false);
      close();
    });
  };

  const toggleSidebar = () => {
    //isSidebarToggled.current = !isSidebarToggled.current;
    //props.hideName();

    if (
      document.body.classList.value === "hidden-sidebar" ||
      document.body.classList.value === "hidden-sidebar show"
    ) {
      document.body.classList.toggle("show");
    } else {
      document.body.classList.toggle("collapsed-sidebar");
    }
  };

  useEffect(() => {
    if (session) makeCall();
    console.log("---", client, vonageUsername);
  }, [session]);

  const makeCall = () => {
    // get token and create a session
    setLoading(true);
    if (!client) {
      axios
        .post("/call")
        .then(async (resp) => {
          console.log("token retreived");
          const token = resp.data.token;
          console.log("token", token);
          setVonageUsername(resp.data.vonage_username);

          const _client = new VonageClient({
            loggingLevel: LoggingLevel.DEBUG,
          });
          //await _client.deleteSession();
          _client
            .createSession(token, localStorage.getItem("vonage_session_id"))
            .then((session) => {
              localStorage.setItem("vonage_session_id", session);
              setSession(session);
            })
            .catch((error) => console.log(error));

          setClient(_client);
        })
        .finally(() => setLoading(false));
    } else {
      console.log(vonageUsername);
      client
        .serverCall({})
        .then((id) => setCallId(id))
        .finally(() => setLoading(false));
      notifications.show({
        title: "Connecting...",
        message: "We are connecting you to our support staff",
      });
    }
  };

  const hangupCall = () => {
    console.log("Hanging up...", callId);
    client.hangup(callId);
    setCallId(null);
  };

  const chooseDoctor = (doctor) => {
    setSelectedDoctor(doctor);
    localStorage.setItem("selected_doctor", doctor.id);
    setContext({ ...context, doctor_id: doctor.id });
    changeDoctorModal.close();
  };

  return (
    <main id="dashboard">
      {context.user.role == "ROLE_PATIENT" && (
        <div className="call-popup">
          <Button
            loading={loading}
            color="green"
            disabled={callId}
            leftSection={<FaPhoneAlt />}
            onClick={() => makeCall(client)}
          >
            Support
          </Button>
          {callId && (
            <Button
              loading={loading}
              color="red"
              leftIcon={<FaTimesCircle />}
              onClick={() => hangupCall()}
            >
              Hang Up
            </Button>
          )}
        </div>
      )}
      <div className="page-container">
        <div className="settings-overlay" />
        <div className="page-content">
          <div className="secondary-sidebar">
            <div id="sidebar-toggle-button-close">
              <i className="icon-close"></i>
            </div>

            <div className="secondary-sidebar-bar">
              <a className="logo-box">
                <img src={process.env.REACT_APP_SITE_LOGO2} alt="" />
              </a>
            </div>

            <div className="mobile-sidebar">
              <a href="#" id="close-menu" onClick={() => toggleSidebar()}>
                <i className="fa fa-times"></i>
              </a>
              <div
                className="secondary-sidebar-menu menu-top scrollbar verticle-scroll"
                onClick={() => isMobile && toggleSidebar()}
              >
                <ul>
                  {role !== "ROLE_DOCTOR" && (
                    <NavItem
                      src="../../assets/dashboard/img/dashboard-icon.png"
                      label="Dashboard"
                      dest={"/dashboard"}
                    />
                  )}

                  {role === "ROLE_DOCTOR" ? (
                    <>
                      {context.user.sites.some((s) => s.name == "omc") && (
                        <NavItem
                          src="../../assets/dashboard/img/recommendation-icon.png"
                          label="OMC Orders"
                          dest={"/orders/omc" }
                          handleClick={() => navigate('/orders/omc', {state: Date.now()})}
                        />
                      )}
                      {context.user.sites.some((s) => s.name == "esa") && (
                        <NavItem
                          src="../../assets/dashboard/img/recommendation-icon.png"
                          label="ESA Orders"
                          dest={"/orders/esa"}
                          handleClick={() => navigate('/orders/esa', {state: Date.now()})}
                        />
                      )}
                      {context.user.steadycare_doctor == 1 && (
                        <NavItem
                          src="../../assets/dashboard/img/recommendation-icon.png"
                          label="Steady Care Leads"
                          dest={"/leads"}
                          handleClick={() => navigate('/leads', {state: Date.now()})}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <NavItem
                        src="../../assets/dashboard/img/recommendation-icon.png"
                        label="Orders"
                        dest={"/orders"}
                      />
                    </>
                  )}

                  {role === "ROLE_DOCTOR" && (
                    <>
                      <NavItem
                        src="../../assets/dashboard/img/report.png"
                        label="Financial Report"
                        dest={"/report"}
                      />
                      <NavItem
                        src="../../assets/dashboard/img/report.png"
                        label="Orders Report"
                        dest={"/report/orders"}
                      />
                    </>
                  )}

                  <NavItem
                    src="../../assets/dashboard/img/profile-icon.png"
                    label="View Profile"
                    dest={"/viewProfile"}
                  />
                  {role !== "ROLE_DOCTOR" && (
                    <>
                      <NavItem
                        src="../../assets/dashboard/img/edit-icon.png"
                        label="Update Profile"
                        dest={"/profileUpdate"}
                      />

                      <NavItem
                        src="../../assets/dashboard/img/setting-icon.png"
                        label="Change Password"
                        dest={"/changePassword"}
                      />
                    </>
                  )}

                  {/*{role !== 'ROLE_DOCTOR' &&*/}
                  {/*    <NavItem*/}
                  {/*    src="../../assets/dashboard/img/Make-Payment.png"*/}
                  {/*    label='Renew'*/}
                  {/*    dest={'/test/dashboard'}*/}
                  {/*/>*/}
                  {/*}*/}

                  {context?.user?.change_doctor == 1 && (
                    <>
                      <NavItem
                        src="../../assets/dashboard/img/change.png"
                        label="Change Doctor"
                        handleClick={() => changeDoctorModal.open()}
                      />
                    </>
                  )}
                </ul>
              </div>
              <div className="sidebar_bottom">
                {context?.user?.change_doctor == 1 && (
                  <div
                    style={{
                      color: "white",
                      paddingLeft: "1em",
                      cursor: "pointer",
                    }}
                    onClick={() => changeDoctorModal.open()}
                  >
                    <p
                      style={{
                        wordBreak: "break-word",
                        fontSize: "10px",
                      }}
                    >
                      {selectedDoctor?.name ?? "Not Selected"}
                    </p>
                  </div>
                )}
                <div
                  className="secondary-sidebar-profile"
                  style={{ marginTop: isMobile ? "" : context.user.role == 'ROLE_PATIENT' && '-3rem' }}
                >
                  <Link to="/viewProfile">
                    <img
                      src={
                        context?.user?.profile_pic === undefined
                          ? `/assets/website/img/defaultImage.jpg`
                          : context?.user?.profile_pic
                      }
                      id="left-profile-img"
                      alt="viewProfile"
                    />
                  </Link>

                  <div
                    id="left-profile-name"
                    onClick={() => isMobile && toggleSidebar()}
                  >
                    <h6 className="profile-text">
                      {context?.user?.name === undefined
                        ? ""
                        : context?.user?.name}
                    </h6>

                    <Link to="/contactUs">
                      <img src="/assets/dashboard/img/Contact-Us.png" alt="" />
                      <span className="profile-text">Contact us</span>
                    </Link>

                    <Link onClick={() => Helpers.Logout()}>
                      <img src="/assets/dashboard/img/logout-icon.png" alt="" />
                      <span className="profile-text">Logout</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-inner no-page-title">
            <div className="page-header-fix">
              <div className="toggle_bar_mob">
                <div
                  onClick={toggleSidebar}
                  id="sidebar-toggle-button"
                  className="mobile-bar"
                >
                  <img
                    src="../../assets/dashboard/img/toggle-icon.png"
                    alt=""
                  />
                  <a className="logo-box">
                    <img src="../../assets/dashboard/img/logo-2.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="navbar_left">
                <ul className="nav navbar-nav">
                  <li className="collapsed-sidebar-toggle-inv d-none d-md-block">
                    <div
                      onClick={toggleSidebar}
                      id="collapsed-sidebar-toggle-button"
                    >
                      <img
                        src="../../assets/dashboard/img/toggle-icon.png"
                        alt=""
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="h-100" id="main-wrapper">
              {children}
            </div>
          </div>
        </div>
        {chatBoxOpen && <ChatBox close={()=>setChatBoxOpen(false)} leadMobile={chat?.mobile} name={chat?.name} email={chat?.email}/> }
      </div>
      <Footer />
      {context.user.role == "ROLE_DOCTOR" && (
        <>
          <Indicator
            inline
            label={rooms.length}
            processing={rooms.length > 0}
            color={rooms.length > 0 ? "red" : "green"}
            size={18}
            style={{
              position: "fixed",
              bottom: "45px",
              right: "60px",
              zIndex: 11,
            }}
          >
            <Button
              onClick={() => open()}
              style={{
                position: "fixed",
                bottom: "10px",
                right: "10px",
                zIndex: 10,
              }}
            >
              <FaVideo />
            </Button>
          </Indicator>
          {context.user.available_for_call && (
            <Button
              onClick={() => activeKeypad()}
              style={{
                position: "fixed",
                bottom: "10px",
                right: "80px",
                zIndex: 10,
              }}
            >
              <FaPhone />
            </Button>
          )}
          {keypad && (
            <div
              style={{
                position: "fixed",
                bottom: "60px",
                right: "10px",
                maxWidth: "270px",
              }}
            >
              <Keypad dial={dial} />
            </div>
          )}
          {isCalling && (
            <div
              style={{
                position: "fixed",
                bottom: "60px",
                right: "10px",
                backgroundColor: "#f1f5f9",
              }}
            >
              <UserCard
                phoneNumber={number}
                userData={userData}
                callId={callId}
                hangUp={hangUp}
              />
            </div>
          )}
          {incomingCall && (
            <div
              style={{
                position: "fixed",
                bottom: "60px",
                right: "10px",
                backgroundColor: "#f1f5f9",
                borderRadius: "12px",
              }}
            >
              <IncomingCallComponent
                answer={answer}
                reject={reject}
                incomingHangUp={incomingHangUp}
                answered={answered}
                callId={callId}
              />
            </div>
          )}
          <Drawer opened={opened} onClose={close} title="Video Calls">
            {rooms.map((room) => (
              <div className="room-list">
                <h5>{room.patient_name}</h5>
                <Button loading={loading} onClick={() => accept(room.id)}>
                  Accept
                </Button>
              </div>
            ))}
            {rooms.length == 0 && (
              <h5 style={{ textAlign: "center" }}>No Any Waiting Patients</h5>
            )}
          </Drawer>
        </>
      )}

      <Modal
        opened={changeDoctorModalOpened}
        size="xl"
        onClose={() => changeDoctorModal.close()}
        title="Change Doctor"
      >
        <Flex wrap={"wrap"} justify={"space-evenly"}>
          {doctors.map((doctor) => (
            <Card
              shadow="sm"
              padding="lg"
              m={10}
              style={{ width: "220px" }}
              radius="md"
              withBorder
            >
              <Card.Section>
                <Image src={doctor.profile_pic} height={160} alt="Doctor" />
              </Card.Section>

              <Text weight={500} align="center" size="sm">
                {doctor.name}
              </Text>

              <Button
                variant="light"
                onClick={() => chooseDoctor(doctor)}
                color="blue"
                fullWidth
                mt="md"
                radius="md"
              >
                Choose
              </Button>
            </Card>
          ))}
        </Flex>
      </Modal>
    </main>
  );
};
export default Layouts;

export function Keypad({ setNumber, dial }) {
  const {
    inputValue,
    setInputValue,
    countryValue,
    setCountryValue,
    activeKeypad,
  } = useContext(AppContext);
  const buttonData = [
    { value: "1" },
    { value: "2" },
    { value: "3" },
    { value: "4" },
    { value: "5" },
    { value: "6" },
    { value: "7" },
    { value: "8" },
    { value: "9" },
    { value: "*" },
    { value: "0" },
    { value: "#" },
  ];
  function setNumber(value) {
    setInputValue((prev) => prev + value);
  }
  function deleteValue() {
    setInputValue((prev) => prev.slice(0, -1));
  }
  return (
    <div style={{ width: "250px" }}>
      <div id="number-keypad" className="number-keypad">
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            value={countryValue}
            onChange={(e) => setCountryValue(e.target.value)}
            className=" countrycode-input"
          />
          <input
            className="number-input"
            placeholder="1234567890"
            id="num-input"
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
          />
          <FaTimes
            style={{
              cursor: "pointer",
              marginBottom: "30px",
              marginRight: "10px",
            }}
            onClick={activeKeypad}
          />
        </div>
        {buttonData.map((button, index) => (
          <div key={index} className="numBtn-wrapper">
            <button
              onClick={() => setNumber(button.value)}
              id="num-btn"
              data-value={button.value}
              className=" num-btn"
            >
              {button.value}
            </button>
          </div>
        ))}
        <div className="keypad-actions">
          <button
            className="call-btn"
            onClick={() => dial(countryValue.replace("+", "") + inputValue)}
          >
            <i className="fa-solid fa-phone"></i>
          </button>
          <button className="remove-btn" onClick={deleteValue}>
            <FaDeleteLeft />
          </button>
        </div>
      </div>
    </div>
  );
}

export function UserCard({ phoneNumber, userData, hangUp, callId }) {
  const [showCallNote, setShowCallNote] = useState(false);
  return (
    <div id="user-card-root" className="user-card">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span className="user-card-avatar">
          <i className="fa-solid fa-user" style={{ color: "#334155" }}></i>
        </span>
        <span className="text-sm" style={{ color: "#4B5563" }}>
          {userData?.url !== "" ? (
            <a
              className="text-sm"
              id="usercard-name"
              target="_blank"
              href={userData?.url}
            >
              {`Calling ${userData.name || "unknown"}`}
            </a>
          ) : (
            <p className="text-sm" id="usercard-name">{`Calling ${
              userData?.name || "unknown"
            }`}</p>
          )}
          <p>{phoneNumber}</p>
          <p>{`State ${userData?.state || "unknown"}`}</p>
        </span>
        <span id="hangup-btn" className="hangup-btn" onClick={hangUp}>
          <i className="fa-solid fa-phone-slash" style={{ color: "white" }}></i>
        </span>
      </div>
      <div style={{ marginTop: "1rem" }}>
        <CallNote
          callId={callId}
          showCallNote={showCallNote}
          setShowCallNote={setShowCallNote}
        />
      </div>
    </div>
  );
}

export function IncomingCallComponent({
  answer,
  reject,
  incomingHangUp,
  answered,
  callId,
}) {
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showCallNote, setShowCallNote] = useState(false);
  useEffect(() => {
    try {
      axios.get(`user/search`).then((res) => {
        setUserData(res.data.data);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    }
  }, []);
  return (
    <div className="incoming-call-wrapper">
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <span style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <span className="user-card-avatar">
            <i className="fa-solid fa-user"></i>
          </span>
          <span className="text-sm" style={{ color: "#334155" }}>
            <p>
              {answered
                ? `on call with: ${userData?.mobile || "unknown"}`
                : `Incoming call: ${userData?.mobile || "unknown"}`}
            </p>
            <p>Name: {userData?.name || "unknown"}</p>
            <p>state: {userData?.state || "unknown"}</p>
          </span>
        </span>
        <span style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          {!answered && (
            <>
              <span
                id="incoming-pickUp"
                className="incoming-action pickup"
                onClick={answer}
              >
                <i className="fa-solid fa-phone"></i>
              </span>
              <span
                id="incoming-reject"
                className="incoming-action reject"
                onClick={reject}
              >
                <i className="fa-solid fa-phone-slash"></i>
              </span>
            </>
          )}
          {answered && (
            <span
              id="incoming-hangUp"
              className="incoming-action hangup"
              onClick={incomingHangUp}
            >
              <i className="fa-solid fa-phone-slash"></i>
            </span>
          )}
        </span>
      </div>
      <CallNote
        callId={callId}
        showCallNote={showCallNote}
        setShowCallNote={setShowCallNote}
      />
    </div>
  );
}
function CallNote({ callId, showCallNote, setShowCallNote }) {
  console.log(callId);
  const [callNote, setCallNote] = useState("");
  const [saving, setSaving] = useState(false);
  function postCallNote() {
    setSaving(true);
    try {
      axios.post(`/call-note`, { note: callNote, call_id: callId }).then(
        (res) => setSaving(false),
        setShowCallNote(false),
        notifications.show({
          message: "The note has been saved sucessfully",
        })
      );
    } catch (error) {
      notifications.show({ message: error?.response?.data?.message });
      setSaving(false);
    }
    console.log(callNote);
  }
  return (
    <>
      {showCallNote && (
        <div>
          <Textarea
            radius="md"
            label="Call Note"
            description="Leave a Note for this call"
            placeholder="Note..."
            value={callNote}
            onChange={(e) => setCallNote(e.target.value)}
          />
          <span style={{ display: "flex", justifyContent: "space-between" }}>
            {" "}
            <Button onClick={postCallNote} loading={saving}>
              {saving ? "saving" : "save"}
            </Button>{" "}
            <Button
              onClick={() => setShowCallNote(false)}
              variant="light"
              color="red"
            >
              Close
            </Button>
          </span>
        </div>
      )}
      {!showCallNote && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p className="text-sm">Leave a note for this call?</p>
          <Button onClick={() => setShowCallNote(true)}>
            <FaPen color="white" />
          </Button>
        </div>
      )}
    </>
  );
}
